import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
    HeaderContainer,
    RelativeWrapperWithImage,
    MainSection,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';
import Joe from 'images/our-team/joe-zhang.png'
import {  HeaderSmall } from 'components/typography'; 
import AirCity from 'pages/SocialMedia/our-partner/images/air-city.png'
import Asendia from 'pages/SocialMedia/our-partner/images/asendia.png'
import Dhl from 'pages/SocialMedia/our-partner/images/dhl.png'
import Didadi from 'pages/SocialMedia/our-partner/images/didadi.png'

import Drop from 'pages/SocialMedia/our-partner/images/drop.png'
import Ebay from 'pages/SocialMedia/our-partner/images/ebay.png'
import Fedex from 'pages/SocialMedia/our-partner/images/fedex.png'
import Geodis from 'pages/SocialMedia/our-partner/images/geodis.png'
import Zulily from 'pages/SocialMedia/our-partner/images/zulily.png'
import Lozi from 'pages/SocialMedia/our-partner/images/lozi.png'
import RoyalMail from 'pages/SocialMedia/our-partner/images/royal-mail.png'
import Shenzen from 'pages/SocialMedia/our-partner/images/shenzen-int.png'

import Shopify from 'pages/SocialMedia/our-partner/images/shopify.png'
import SwiftRobotics from 'pages/SocialMedia/our-partner/images/swift-robotics.png'

const Wrapper = styled.div`
    padding-top: 100px;
    text-align: center;
    background: #c2e59c; 
    background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);
    background: linear-gradient(to right, #64b3f4, #c2e59c);
    }

    .column {
        display: grid;
        grid-template-columns: auto auto auto auto;
        padding: 10px;
    }

    img {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 100px;
        margin-bottom: 50px;
    }
`;

const Row = styled.div`
    width: 100%;
`;

const WhyUs = () => {
    const intl = useIntl()

    return (
           <Wrapper>
                <div className="row">
                    <div className="column">
                        <img src={Asendia} />
                        <img src={Dhl} />
                        <img src={Didadi} />
                        <img src={Ebay} />
                        <img src={Fedex} />
                        <img src={Didadi} />
                        <img src={Zulily} />
                        <img src={Lozi} />
                        <img src={RoyalMail} />
                        <img src={Shenzen} />
                        <img src={Shopify} />
                        <img src={SwiftRobotics} />
                    </div>
                </div>
            </Wrapper>
    );
};

export default WhyUs;